import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';
import { validators } from '@/shared/form/validators';

export default class ComputedField extends GenericField {
    constructor(
        name,
        label,
        computeFn,
        { required = false } = {},
        filterParams = {},
    ) {
        super(name, label, filterParams);

        this.required = required;
        this.computeFn = computeFn;
        this.type = 'search';
    }

    forPresenter(value) {
        return this.computeFn(value);
    }

    forFormRules() {
        let output = {};

        if (this.required) {
            output = {
                ...output,
                ...validators.required(),
            };
        }

        return output;
    }

    forFilterRules() {
        return undefined;
    }

    forFormInitialValue(value) {
        return this.computeFn(value);
    }

    forFilterInitialValue(value) {
        return this.computeFn(value);
    }

    forFormCast() {
        let yupChain = yup
            .mixed()
            .nullable(true)
            .label(this.label);

        return yupChain;
    }

    forFilterCast() {
        return yup
            .mixed()
            .label(this.label);
    }
}
