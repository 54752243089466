<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="loading['applications/get']"
            @view="onView"
            responsive="sm"
            hover
            :customClass="customHeightClass"
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { ApplicationModel } from '@/modules/applications/models/application-model';
    const { fields } = ApplicationModel;

    export default {
        name: 'RecentApplicationsListTable',
        props: {
            isStaff: {
                type: Boolean,
                required: true
            },
            columnsHeights: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                presenter: ApplicationModel.presenter,
                actions: [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'applications/list/rows',
                loading: 'shared/loading',
            }),
            fields() {
                if (this.isStaff) {
                    return [
                        fields.created_by,
                        fields.beneficiary.extendField({ customField: true, portalTarget: 'beneficiary-custom-field', sortable: false}),
                        fields.identification_number.extendField({ sortable: true}),
                        fields.application_type_name,
                        fields.application_date.extendField({ bold: true}),
                        fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                        fields.is_paid.extendField({ booleanType: true }),
                        fields.status.extendField({ labelType: true }),
                    ];
                } else {
                    return [
                        fields.identification_number.extendField({ sortable: true}),
                        fields.application_type_name.extendField({ customField: true, portalTarget: 'enable-truncate', sortable: true}),
                        fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                        fields.response_due_in_days.extendField({ bold: true, sortable: true}),
                        fields.is_paid.extendField({ booleanType: true }),
                        fields.is_signed.extendField({ booleanType: true }),
                        fields.status.extendField({ labelType: true }),
                    ];
                }
            },
            customHeightClass() {
                return this.columnsHeights && this.columnsHeights === 'dasboard-some-results' ?
                        'custom-middle-table-height' : 'custom-full-table-height';
            }
        },
        methods: {
            ...mapActions({
                selectRecord: 'applications/form/find',
            }),
            async onView(data) {
                if (!data?.item?.id) return;
                const application = await this.selectRecord({ id: data.item.id, loadStaff: this.isStaff});
                const routeName =  application?.status === 'draft' ? 'applicationEdit' : 'applicationView';
                this.$router.push({
                    name: routeName,
                    params: {
                        id: data.item.id,
                    },
                });
            },
        }
    };
</script>
<style >
.custom-middle-table-height {
    height: 200px;
}
.custom-full-table-height {
     height: 300px;
}
</style>
