import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';
import { storeAsync } from '@/app-module';

export class ApplicationTypeField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        const store = storeAsync();

        return new RelationToOneField(
            name,
            label,
            () => {
                const isAuthenticated = store.getters['auth/isAuthenticated'];

                if(!isAuthenticated) return Promise.resolve([]);

                return new Promise(async (resolve) => {
                    let params = {
                        query: 'is_internal ne true and status eq active',
                        limit: 200,
                        sort: '-order',
                    }

                    ApiService.query('/application-types', { params }).then(({ data }) => {
                        resolve(data);
                    });
                });
            },
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                    key: record.key,
                    tax_category_conditions: record.tax_category_conditions,
                    is_tax_category_conditional: record.is_tax_category_conditional,
                };
            },
            rules,
            filterParams,
            children,
            options
        );
    }
}
