import * as yup from 'yup';
import i18n from '@/shared/plugins/vue-i18n';
import GenericField from '@/shared/fields/generic-field';

export default class BooleanField extends GenericField {
    constructor(
        name,
        label,
        { yesLabel = undefined, noLabel = undefined } = {},
        filterParams = {},
    ) {
        super(name, label, filterParams);

        this.type = 'boolean';
        this.yesLabel = yesLabel || i18n.t('GENERAL.YES');
        this.noLabel = noLabel || i18n.t('GENERAL.NO');
    }

    forPresenter(value) {
        return value ? this.yesLabel : this.noLabel;
    }

    forFilterInitialValue(value) {
        return value;
    }

    forFormInitialValue(value) {
        return value;
    }

    forFormRules() {
        return undefined;
    }

    forFilterRules() {
        return undefined;
    }

    forFormCast() {
        let yupChain = yup
            .bool()
            .default(false)
            .label(this.label);
        return yupChain;
    }

    forFilterCast() {
        let yupChain = yup.bool().label(this.label);
        return yupChain;
    }
}
